import React, { useState } from "react";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";

import cx from "classnames";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  makeStyles,
  Paper,
  Popover,
  Tab,
} from "@material-ui/core";
import {
  useCommonStyles,
  useStyles as useStepStyles,
} from "../../../../utils/loan-application.styles";
import { primaryColor } from "../../../../scss/colors.scss";
import Button from "../Button";
import { sortArrayByIndexOfOriginalArray } from "../../../../utils/loan-application.utils";

const useStyles = makeStyles({
  tabsRoot: {
    borderBottom: "2px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: primaryColor,
    height: "3px",
  },
  tabRoot: {
    padding: "10px 25px",
    textTransform: "none",
    minWidth: "unset",
    "& > span": {
      fontSize: "18px",
      lineHeight: "22px",
      width: "auto",
      fontWeight: "400",
    },
  },
  tabPanelRoot: {
    padding: "48px 0px !important",
  },
  disabledCheckbox: {
    "&.Mui-disabled": {
      color: primaryColor,
      borderColor: "unset",
    },
  },
  saveButton: {
    padding: "8px 16px",
  },
});

const DynamicTabs = ({
  tabLists,
  expandButtonTitle,
  selectedTabs,
  setSelectedTabs,
  currentTab,
  onSelectTab,
  formik,
  completedStep,
  onEdit,
  handleNextStep,
  handleSaveSection,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const stepClasses = useStepStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedTabValues = new Set(selectedTabs);
  const isOpenDropdown = Boolean(anchorEl);
  const [addedTabs, setAddedTabs] = useState(new Set());
  const displayTabs = [...selectedTabValues].map((tabValue) =>
    tabLists.find((tab) => tab.value === tabValue)
  );
  displayTabs.push({
    key: expandButtonTitle,
    title: expandButtonTitle,
    value: expandButtonTitle,
  });

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    const sortedNewTabs = sortArrayByIndexOfOriginalArray(tabLists, [
      ...addedTabs,
    ]);
    setSelectedTabs([...selectedTabs, ...sortedNewTabs]);
    setAddedTabs(new Set());
    setAnchorEl(null);
  };

  const handleSelectOption = (value) => {
    if (selectedTabValues.has(value)) {
      return;
    }
    const newTabsSet = new Set([...addedTabs]);
    if (newTabsSet.has(value)) {
      newTabsSet.delete(value);
    } else {
      newTabsSet.add(value);
    }
    setAddedTabs(newTabsSet);
  };

  const onTabChange = (event, newValue) => {
    if (newValue === expandButtonTitle) {
      return;
    }
    onSelectTab(event, newValue);
  };

  return (
    <>
      <TabContext value={currentTab}>
        <TabList
          onChange={onTabChange}
          TabIndicatorProps={{ children: <span /> }}
          scrollButtons="desktop"
          variant="scrollable"
          classes={{
            root: cx(classes.tabsRoot, commonClasses.marginBottom24),
            indicator: classes.indicator,
          }}
        >
          {displayTabs.map((tabItem, index) => {
            if (tabItem.value === expandButtonTitle) {
              return (
                <Tab
                  disableRipple
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}_${tabItem.key}`}
                  fullWidth={false}
                  label={expandButtonTitle}
                  value={expandButtonTitle}
                  classes={{
                    root: classes.tabRoot,
                  }}
                  onClick={handleOpenDropdown}
                />
              );
            }
            return (
              <Tab
                disableRipple
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}_${tabItem.key}`}
                fullWidth={false}
                label={tabItem.title}
                value={tabItem.value}
                classes={{
                  root: classes.tabRoot,
                }}
              />
            );
          })}
        </TabList>
        {displayTabs.map(
          (tab, index) =>
            tab.value !== expandButtonTitle && (
              <TabPanel
                value={tab.value}
                key={tab.key}
                classes={{ root: stepClasses.tabPanelRootMobileNoPadding }}
              >
                <tab.component
                  formik={formik}
                  index={index}
                  completedStep={completedStep}
                  onEdit={onEdit}
                  handleNextStep={handleNextStep}
                  handleSaveSection={handleSaveSection}
                  currentStep={currentTab}
                />
              </TabPanel>
            )
        )}
      </TabContext>
      <Popover
        open={isOpenDropdown}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleCloseDropdown}
      >
        <Paper>
          <List>
            {tabLists.map((option) => (
              <ListItem
                key={option.key}
                dense
                button
                onClick={() => handleSelectOption(option.value)}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  gridColumnGap="48px"
                  alignItems="center"
                >
                  {option.label}
                  <ListItemIcon
                    classes={{
                      root: commonClasses.disabledMinWidth,
                    }}
                  >
                    <Checkbox
                      color="secondary"
                      checked={
                        selectedTabValues.has(option.value) ||
                        addedTabs.has(option.value)
                      }
                      disabled
                      classes={{
                        root: cx(
                          addedTabs.has(option.value) &&
                            classes.disabledCheckbox
                        ),
                      }}
                    />
                  </ListItemIcon>
                </Box>
              </ListItem>
            ))}
            <ListItem dense>
              <Box minHeight="42px">
                <ListItemSecondaryAction>
                  <Button
                    classes={classes.saveButton}
                    onClick={handleCloseDropdown}
                  >
                    Save
                  </Button>
                </ListItemSecondaryAction>
              </Box>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </>
  );
};

export default DynamicTabs;
